import React, { useEffect, useState,useRef } from "react";

// import "./index.css";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Row } from "react-bootstrap";
import html2pdf from "html2pdf.js/dist/html2pdf.js";
import Logo from "../../../assets/images/omw-logo.png"
import { OMWLogoBlack } from "../../../constants/Images";
import ModalHeader from '../../shared/Modal/ModalHeader'
import "./index.css"

const ProofOfAccountModal = (props: {

    showPoaModal: boolean,
    handleClose: () => void

}) => {

    const { t } = useTranslation();
    const proofModalRef = useRef(null);

    const { showPoaModal, handleClose, } = props;

    const companyName: any = localStorage.getItem("companyName");
    const companyRegNumber: any = localStorage.getItem("companyRegNumber");

    const accountData: any = localStorage.getItem("AccountData");
    const parsedData: any = JSON.parse(accountData);

    const [bankCode, setBankCode] = useState("");
    const [flag, setFlag] = useState(false);

    const createStatementPDF = () => {
        // setIsloading(true);
        const element = document.getElementById("statementDetailsCapture"); // Replace 'html-content' with the id of your HTML element
        const fileName = `${companyName}_${new Date().toISOString().slice(0, 10)}.pdf`;
        const opt = {
            filename: fileName,
            margin: [10, 10, 10, 10],
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { dpi: 96, letterRendering: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };

        html2pdf().set(opt).from(element).save();
        // setIsloading(false);
    };

    useEffect(() => {
        if (parsedData?.IBAN.slice(0, 2) === "DK") {
            setBankCode(parsedData?.IBAN.slice(4, 8));

            setFlag(true);
        } else {
            setFlag(false);
        }
    }, [bankCode, flag]);




    return (

        <div>
            <Modal className="pos-modal-root" style={{ marginTop: 50, padding: '0px 0px 20px 0px' }} show={showPoaModal} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
                {/* <Modal.Header closeButton>
                    <Modal.Title>{t("statement.pdf.accountStatementDetails")}</Modal.Title>
                </Modal.Header> */}
                <ModalHeader title={t("statement.pdf.accountStatementDetails")} onClose={handleClose} modalBodyRef={proofModalRef}/> 
                <Modal.Body ref={proofModalRef}>
                    {/* <div id="statementDetailsCapture" className="modal-body">
                        <div className="d-flex flex-row justify-content-between align-items-start w-100">
                            <div className="w-100">
                            </div>
                            <div className="text-end">
                                <div>
                                    <img style={{ width: 220, marginRight: '30px' }} src={Logo} alt="logo" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Row className="account-detail-row">
                                <Col md={12}>
                                    <div className="fw-bold" style={{ fontSize: "30px", marginBottom: '20px' }}>{t('statement.pdf.accountStatementDetailTitle')}</div>
                                    <p className="mb-0">{t('statement.pdf.accountStatementDetailBody1')}</p>
                                    <p className="mb-10" style={{ textAlign: 'justify'}}>
                                        {t('statement.pdf.accountStatementDetailBody2')}
                                        <b>{companyName}</b>
                                        {t('statement.pdf.accountStatementDetailBody3')}

                                        <b>{companyName}</b>
                                        {t('statement.pdf.accountStatementDetailBody4')}
                                    </p>
                                    <div className="mb-1"> <span className="fw-bold"> {t("statement.pdf.companyName")} : </span>{companyName}</div>
                                    <div className="mb-1">
                                        <span className="fw-bold">{t("statement.pdf.address")} : </span>{parsedData?.bankAddress}
                                    </div>
                                    <div className="mb-1">
                                        <span className="fw-bold">{t("statement.pdf.regNo")} : </span>{companyRegNumber}
                                    </div>
                                    <div className="mb-1">
                                        <span className="fw-bold">{t("statement.pdf.accountType")} : </span>{"Current"}
                                    </div>
                                    <div className="mb-1">
                                        <span className="fw-bold">{t("statement.pdf.currency")} : </span>{parsedData?.currency}
                                    </div>
                                    <div className="mb-1">
                                        <span className="fw-bold">{t("statement.pdf.iban")} : </span>{parsedData?.IBAN}
                                    </div>
                                    <div><span className="fw-bold">{t("statement.pdf.swift")} : </span>{parsedData?.swiftCode}</div>
                                    {
                                        parsedData?.IBAN.slice(0, 2) === "DK" && parsedData?.currency === 'DKK' ? <>
                                            <div><span className="fw-bold">{t("statement.pdf.bankCode")} : </span>{parsedData?.IBAN.slice(4, 8)}</div>
                                            <div><span className="fw-bold">{t("statement.pdf.accountNumber")} : </span>{parsedData?.accountNumber || parsedData?.IBAN.slice(-10)}</div>
                                        </> : null
                                    }

                                </Col>

                            </Row>

                        </div>
                        <Row>
                            <Col md={12} className="mt-4" style={{ width: "100%", justifyContent: 'center', }}>
                                <div className="fw-bold pb-2" style={{textAlign:"center"}}>{t("statement.pdf.dateCreated") + ": " + new Date().toDateString()}</div>
                                <div style={{ textAlign: 'justify'}}>{t("statement.pdf.desc")}</div>
                                <div style={{textAlign:"center"}}>{t("statement.pdf.copyright")}</div>
                            </Col>
                        </Row>


                    </div>
                    <Row className="pt-3">
                        <Button variant="primary" className="d-block w-100" onClick={createStatementPDF}>
                            {t('recentOperation.button.download')}
                        </Button>
                    </Row> */}
                    {/* <Table>
              <tbody>
                <tr>
                  <td>{t("bankAccounts.modal.bank")}:</td>
                  <td>{parsedData?.bank}</td>
                </tr>
                <tr>
                  <td>{t("bankAccounts.modal.address")}:</td>
                  <td>{parsedData?.bankAddress}</td>
                </tr>
                <tr>
                  <td>{t("bankAccounts.modal.name")}:</td>
                  <td>{parsedData?.accountName}</td>
                </tr>
                <tr>
                  <td>{t("bankAccounts.modal.sortCode")}:</td>
                  <td>{parsedData?.sortCode}</td>
                </tr>
                {flag ? (
                  <tr>
                    <td>{t("bankAccounts.modal.bankCode")}:</td>
                    <td>{bankCode}</td>
                  </tr>
                ) : (
                  ""
                )}
                <tr>
                  <td>{t("bankAccounts.modal.accountNo")}:</td>
                  <td>
                    {flag
                      ? parsedData?.IBAN.slice(8)
                      : parsedData?.accountNumber}
                  </td>
                </tr>
                <tr>
                  <td>{t("bankAccounts.modal.iban")}:</td>
                  <td>{parsedData?.IBAN}</td>
                </tr>
                <tr>
                  <td>{t("bankAccounts.modal.swiftCode")}:</td>
                  <td>{parsedData?.swiftCode}</td>
                </tr>
              </tbody>
            </Table> */}

<div id="statementDetailsCapture">
    <div className="OMW_logo">
        <div className=""></div>
        <img style={{ width: 220, marginRight: '30px' }} src={OMWLogoBlack} alt="logo" />  
    </div>
    <div>
        <div style={{fontWeight: 600}} className="POAD_Heading mt-4 mb-4">{t('statement.pdf.accountStatementDetailTitle')}</div>
    </div>
    <div>
         <p className="mb-10" style={{ textAlign: 'justify'}}>
            {t('statement.pdf.accountStatementDetailBody2')}
            <b style={{fontWeight: 600}}>{companyName}</b>
            {t('statement.pdf.accountStatementDetailBody3')}

            <b style={{fontWeight: 600}}>{companyName}</b>
            {t('statement.pdf.accountStatementDetailBody4')}
        </p>
    </div>
    <div>
        <table>
            <tr>
                <td style={{fontWeight: 600}}>{t("statement.pdf.companyName")} :</td>
                <td>{companyName}</td>
            </tr>
            <tr>
                <td style={{fontWeight: 600}}>{t("statement.pdf.address")} :</td>
                <td>{parsedData?.bankAddress}</td>
            </tr>
            <tr>
                <td style={{fontWeight: 600}}>{t("statement.pdf.regNo")} :</td>
                <td>{companyRegNumber}</td>
            </tr>
            <tr>
                <td style={{fontWeight: 600}}>{t("statement.pdf.accountType")} :</td>
                <td>{"Current"}</td>
            </tr>
            <tr>
                <td style={{fontWeight: 600}}>{t("statement.pdf.currency")} :</td>
                <td>{parsedData?.currency}</td>
            </tr>
            <tr>
                <td style={{fontWeight: 600}}>{t("statement.pdf.iban")} :</td>
                <td>{parsedData?.IBAN}</td>
            </tr>
            <tr>
                <td style={{fontWeight: 600}}>{t("statement.pdf.swift")} :</td>
                <td>{parsedData?.swiftCode}</td>
            </tr>
            {
                parsedData?.IBAN.slice(0, 2) === "DK" && parsedData?.currency === 'DKK' ? <>
                <tr>
                     <td style={{fontWeight: 600}}>{t("statement.pdf.bankCode")} :</td>
                     <td>{parsedData?.IBAN.slice(4, 8)}</td>
                </tr>
                <tr>
                     <td style={{fontWeight: 600}}>{t("statement.pdf.accountNumber")} :</td>
                     <td>{ parsedData?.IBAN.slice(-10) }</td>
                </tr>
                </> : null
            }
        </table>
        {/* <div className="mb-1"> <span className="fw-bold"> {t("statement.pdf.companyName")} : </span>{companyName}</div>
            <div className="mb-1">
                <span className="fw-bold">{t("statement.pdf.address")} : </span>{parsedData?.bankAddress}
            </div>
            <div className="mb-1">
                <span className="fw-bold">{t("statement.pdf.regNo")} : </span>{companyRegNumber}
            </div>
            <div className="mb-1">
                <span className="fw-bold">{t("statement.pdf.accountType")} : </span>{"Current"}
            </div>
            <div className="mb-1">
                <span className="fw-bold">{t("statement.pdf.currency")} : </span>{parsedData?.currency}
            </div>
            <div className="mb-1">
                <span className="fw-bold">{t("statement.pdf.iban")} : </span>{parsedData?.IBAN}
            </div>
            <div><span className="fw-bold">{t("statement.pdf.swift")} : </span>{parsedData?.swiftCode}</div>
            {
                parsedData?.IBAN.slice(0, 2) === "DK" && parsedData?.currency === 'DKK' ? <>
                    <div><span className="fw-bold">{t("statement.pdf.bankCode")} : </span>{parsedData?.IBAN.slice(4, 8)}</div>
                    <div><span className="fw-bold">{t("statement.pdf.accountNumber")} : </span>{parsedData?.accountNumber || parsedData?.IBAN.slice(-10)}</div>
                </> : null
            } */}
        </div>
    <div>
    <div className="mt-4" style={{ width: "100%", justifyContent: 'center', }}>
         <div className="pb-2 date-created" style={{textAlign:"center",fontWeight: 600}}>{t("statement.pdf.dateCreated") + ": " + new Date().toDateString()}</div>
         <div className="desc" style={{ textAlign: 'justify'}}>{t("statement.pdf.desc")}</div>
         <div className="copyright" style={{textAlign:"center"}}>{t("statement.pdf.copyright")}</div>
    </div>
    </div>
</div>
    <div className="mb-3 mt-4" style={{display:"flex",columnGap:"10px"}}>
        <Button variant="primary" className="d-block w-100" onClick={createStatementPDF}>
            {t('recentOperation.button.download')}
        </Button>
        <Button
              variant="outline-primary"
              className="d-block w-100"
              onClick={handleClose}
            >
              {t("recentOperation.button.close")}
            </Button>
    </div>



                </Modal.Body>
            </Modal>

        </div>
    );
};

export default ProofOfAccountModal;
