import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoPersonAddSharp, IoNewspaperSharp, IoCard } from "react-icons/io5";
import { LOGO_URL } from '../../../constants/stringConstants'
import { OMWLogoBlack } from '../../../constants/Images/index'
// import Convert from "../../../assets/images/convert-funds.png";
import LanguageDropdown from "../TranslationDropdown";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";
import { PathStrConst } from "../../../constants";
import { staticRoles } from '../../../utils/constants'

import {
  Home,
  SelectedHome,
  MyCards,
  Transaction,
  Convert,
  Account,
  TransferMoney,
  CardPayment,
  Invoice,
  Statement,
  SelectedCards,
  SelectedAccount,
  SelectedConvert,
  SelectedTransfer,
  SelectedCardPayment,
  SelectedInvoice,
  SelectedStatement,
} from "../../../constants/Images";
import BankAccounts from "../../../pages/BankAccounts";


const LeftSideBar = (props: { name: string }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPath = pathname.replace("", "/")
  console.log('currentPath: ', currentPath);
  const { t } = useTranslation();
  const { ADMIN, ACCOUNTANT, SUPER_ADMIN } = staticRoles;

  const [activeTabs, setActiveTabs] = useState(props.name || currentPath);
  console.log('activeTabs: ', activeTabs);

  const userEmail: any = localStorage.getItem("userEmail");
  const sessionEmail = localStorage.getItem('session_email');
  const roles: string[] = JSON.parse(localStorage.getItem('roles') || "[]");

  const sideMenu = [
    {
      name: "home",
      route: "home",
      selected: SelectedHome,
      unseleted: Home,
      roles: [ADMIN, ACCOUNTANT, SUPER_ADMIN]
    },
    {
      name: "cards",
      route: "bankAccountCards",
      selected: SelectedCards,
      unseleted: MyCards,
      roles: [ADMIN, ACCOUNTANT, SUPER_ADMIN]
    },
    {
      name: "convert",
      route: "convert",
      selected: SelectedConvert,
      unseleted: Convert,
      roles: [ADMIN, SUPER_ADMIN]
    },
    {
      name: "accountInfo",
      route: "generalInfo",
      selected: SelectedAccount,
      unseleted: Account,
      roles: [ADMIN, ACCOUNTANT, SUPER_ADMIN]
    },
    {
      name: "transfer",
      route: "sendFunds",
      selected: SelectedTransfer,
      unseleted: TransferMoney,
      roles: [ADMIN, ACCOUNTANT, SUPER_ADMIN]
    },
    {
      name: "recipient",
      route: "addbeneficiary",
      selected: SelectedCardPayment,
      unseleted: CardPayment,
      roles: [ADMIN, ACCOUNTANT, SUPER_ADMIN]
    },
    {
      name: "invoice",
      route: "invoice",
      selected: SelectedInvoice,
      unseleted: Invoice,
      roles: [ADMIN, ACCOUNTANT, SUPER_ADMIN]
    },
    {
      name: "statement",
      route: "statement",
      selected: SelectedStatement,
      unseleted: Statement,
      roles: [ADMIN, ACCOUNTANT, SUPER_ADMIN]
    },
    {
      name: "cardPayment",
      route: "cardPayments",
      selected: SelectedCardPayment,
      unseleted: CardPayment,
      roles: [ADMIN, ACCOUNTANT]
    },
  ]




  

  useEffect(() => {
    if (pathname == "privacyPolicy" || pathname == "OMWfees") {
      setActiveTabs('')
    }
    else {
      setActiveTabs(props.name || currentPath)
    }
  }, [pathname])

  useEffect(() => {
    switch (activeTabs) {
      case "generalInfo":
        navigate(PathStrConst.generalInformation);
        break;
      case "bankAccountCards":
        navigate("/bankAccountCards");
        break;
      case "home":
        navigate(PathStrConst.bankAccount);
        break;
      case "convert":
        navigate(PathStrConst.convert);
        break;
      case "sendFunds":
        navigate(PathStrConst.sendFunds);
        break;
      case "addbeneficiary":
        navigate(PathStrConst.addbeneficiary);
        break;
      case "invoice":
        navigate(PathStrConst.invoice);
        break;
      case "interestAccount":
        navigate(PathStrConst.interestaccount);
        break;
      case "editRegistration":
        navigate(PathStrConst.editregistration);
        break;
      case "statement":
        navigate(PathStrConst.statement);
        break;
      case "cardPayments":
        navigate(PathStrConst.CardPayments);
        break;
    }
  }, [activeTabs]);

  const getTabColor = (tabName: string) => {
    // eslint-disable-next-line no-constant-condition
    // const color = activeTabs === tabName ? " linear-gradient(90deg, #9CC5F8 0%, rgba(217, 217, 217, 0) 67.43%)" : "";
    const color = activeTabs === tabName ? "6px solid #006ff4" : "";
    return color;
  };

  const getTabLinkColor = (tabName: string) => {
    // eslint-disable-next-line no-constant-condition
    // const color = activeTabs === tabName ? " linear-gradient(90deg, #9CC5F8 0%, rgba(217, 217, 217, 0) 67.43%)" : "";
    const color = activeTabs === tabName ? "#006ff4" : "";
    return color;
  };



  return (
    <div className="left-menu">
      <div className="general_logo-box">
        <img className="drawer-logo" style={{ width: 130, height: 'auto', filter: "drop-shadow(0 0 0.4px)", marginTop: "20px", marginLeft: '10px' }} src={OMWLogoBlack} alt="logo" />
      </div>
      <div className="leftSidebar-NavAnddropdown">
        <nav className="general_nav-menu">
          <ul className="p-0 nav-menu_link-wrapper">
            {
              sideMenu.map((e, index) => {
                if(!roles || roles?.length === 0 || e?.route === 'home' || roles?.includes(ADMIN) || roles?.includes(SUPER_ADMIN) || ( e?.roles.some(item => roles?.includes(item)) && sessionEmail)) {
                  return (
                    <li
                      key={index}
                      className="NavList-Li"
                      onClick={() => setActiveTabs(e?.route)}
                      style={{ borderRight: getTabColor(e?.route) }}
                    >
                      <img
                        className="tabsIcon"
                        src={activeTabs === e?.route ? e?.selected : e?.unseleted}

                        alt="send-founds-ico"
                      ></img>
                      <p className="tabLink" style={{ color: getTabLinkColor(e?.route) }}>{t(`leftSidebar.${e?.name}`)}</p>
                    </li> 
                  )
                }
              })
            }
            {/* <li
              className="NavList-Li"
              onClick={() => setActiveTabs("home")}
              style={{ borderRight: getTabColor("home") }}
            >
              <img
                className="tabsIcon"
                src={activeTabs === "home" ? SelectedHome : Home}
                alt="bank-link-ico"
              ></img>
              <p className="tabLink"
                style={{ color: getTabLinkColor("home") }}
              >{t("leftSidebar.home")}</p>
            </li> */}
            {/* Bank Account  Cards */}
            {/* {userEmail === "sheheryar@onemoneyway.com" || userEmail === "saad.shahid@onemoneyway.com"
              ? <li
                className="NavList-Li"
                onClick={() => setActiveTabs("bankAccountCards")}
                style={{ borderRight: getTabColor("bankAccountCards") }}
              >
                <img
                  className="tabsIcon"
                  src={activeTabs === "bankAccountCards" ? SelectedCards : MyCards}
                  alt="bank-link-ico"
                ></img>
                <p className="tabLink" style={{ color: getTabLinkColor("bankAccountCards") }}>{t("leftSidebar.cards")}</p>
              </li>
              : <></>
            } */}

            {/* <li
              className="NavList-Li"
              onClick={() => setActiveTabs("convert")}
              style={{ borderRight: getTabColor("convert") }}
            >
              <img className="tabsIcon"
                src={activeTabs === "convert" ? SelectedConvert : Convert}

                alt="convert-ico"></img>
              <p className="tabLink" style={{ color: getTabLinkColor("convert") }}>{t("leftSidebar.convert")}</p>
            </li> */}

            {/* <li
              className="NavList-Li"
              onClick={() => setActiveTabs("generalInfo")}
              style={{ borderRight: getTabColor("generalInfo") }}
            >
              <img
                className="tabsIcon"
                src={activeTabs === "generalInfo" ? SelectedAccount : Account}
                alt="general_link-ico"
              ></img>
              <p className="tabLink" style={{ color: getTabLinkColor("generalInfo") }}>{t("leftSidebar.accountInfo")}</p>
            </li> */}

            {/* <li
              className="NavList-Li"
              onClick={() => setActiveTabs("sendFunds")}
              style={{ borderRight: getTabColor("sendFunds") }}
            >
              <img
                className="tabsIcon"
                src={activeTabs === "sendFunds" ? SelectedTransfer : TransferMoney}
                alt="send-founds-ico"
              ></img>
              <p className="tabLink" style={{ color: getTabLinkColor("sendFunds") }}>{t("leftSidebar.transfer")}</p>
            </li> */}
            {/* <li
              className="NavList-Li"
              onClick={() => setActiveTabs("addbeneficiary")}
              style={{ borderRight: getTabColor("addbeneficiary") }}
            >
              <img
                className="tabsIcon"
                src={activeTabs === "addbeneficiary" ? SelectedCardPayment : CardPayment}

                alt="send-founds-ico"
              ></img>
              <p className="tabLink" style={{ color: getTabLinkColor("addbeneficiary") }}>{t("leftSidebar.recipient")}</p>
            </li> */}
            {/* Invoice */}
            {/* <li
              className="NavList-Li"
              onClick={() => setActiveTabs("invoice")}
              style={{ borderRight: getTabColor("invoice") }}
            >
              <img
                className="tabsIcon"
                src={activeTabs === "invoice" ? SelectedInvoice : Invoice}

                alt="send-founds-ico"
              ></img>
              <p className="tabLink" style={{ color: getTabLinkColor("invoice") }}>{t("leftSidebar.invoice")}</p>
            </li> */}

            {/* Uncomment to see Earn 7% Interest */}

            {/* <li
              className="general_send-link"
              onClick={() => setActiveTabs("interestAccount")}
              style={{ background: getTabColor("interestAccount") }}
            >
              <div className="add-recipient-img">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="44" fill="currentColor" className="bi bi-piggy-bank-fill" viewBox="0 0 16 16">
                  <path d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595Zm7.173 3.876a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199Zm-8.999-.65a.5.5 0 1 1-.276-.96A7.613 7.613 0 0 1 7.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 1 1-.287.958A6.602 6.602 0 0 0 7.964 4.5c-.64 0-1.255.09-1.826.254ZM5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                </svg></div>
              <p className="sf-link">{t("leftSidebar.interestAccount")}</p>
            </li> */}
            {/* <li
              className="NavList-Li"
              onClick={() => setActiveTabs("statement")}
              style={{ borderRight: getTabColor("statement") }}
            >
              <img
                className="tabsIcon"
                src={activeTabs === "statement" ? SelectedStatement : Statement}

                alt="send-founds-ico"
              ></img>
              <p className="tabLink" style={{ color: getTabLinkColor("statement") }}>{t("leftSidebar.statement")}</p>
            </li> */}
            {/* {userEmail === "sheheryar@onemoneyway.com" || userEmail === "saad.shahid@onemoneyway.com"
            ? */}
            {/* {
              userEmail === "sheheryar@onemoneyway.com" && (
                <li
                  className="NavList-Li"
                  onClick={() => setActiveTabs("cardPayments")}
                  style={{ borderRight: getTabColor("cardPayments") }}
                >
                  <img
                    className="tabsIcon"
                    src={activeTabs === "cardPayments" ? SelectedCardPayment : CardPayment}

                    alt="send-founds-ico"
                  ></img>
                  <p className="tabLink" style={{ color: getTabLinkColor("cardPayments") }}>{t("leftSidebar.cardPayment")}</p>
                </li>
              )
            } */}
          {/* //   :<></> */}
          {/* // } */}
          </ul>
        </nav>
        {/* <div className="lang-selector">
          <LanguageDropdown />
        </div> */}

      </div>
      <div className="leftSidebarFooter">
        <div className="leftSidebarPrivacyPolicy">
          <p className="privacyPolicyPara" onClick={() => navigate("/privacyPolicy")}>
            Privacy Policy
          </p>
          <p>|</p>
          <p className="feesPara" onClick={() => navigate("/OMWfees")}>OMW Fees</p>
        </div>
        <p className="leftSidebarCopyRights">
          © OneMoneyWay {new Date().getFullYear()}.
        </p>
      </div>
    </div>
  );
};

export default LeftSideBar;
