import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <div className={styles["container-style"]}>
      <div className={styles["image-container-style"]}>
        <img src="page-not-found.svg" className={styles["image-style"]} />
      </div>
      <span className={styles["title-style"]}>{t("pageNotFound.title")}</span>
      <span className={styles["description-style"]}>
        {t("pageNotFound.description")}
      </span>
    </div>
  );
};

export default PageNotFound;
