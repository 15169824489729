import React from "react";
import { Spinner } from "react-bootstrap";

import "../index.css";

const IconButton = (props: any) => {
  // console.log("IconButtonprops: ", props);
  return (
    <button className={`basicButton ${props?.styleClass}`} {...props}>
      {props?.isLoading != true ? (
        <span className="prefixIcon">
          {props?.prefixIcon && props?.prefixIcon}
        </span>
      ) : (
        ""
      )}

      {props?.isLoading ? <Spinner animation={"border"} /> : props?.text}
      {props?.isLoading != true ? (
        <span className="suffixIcon">
          {props?.suffixIcon && props?.suffixIcon}
        </span>
      ) : (
        ""
      )}
    </button>
  );
};
export default IconButton;
