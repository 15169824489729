import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiHelper } from "../../../api helper";
import { RecentOperationResponse, RecentOperationPayload ,RecentOperationPaginatedPayload,RecentOperationPaginatedResponse} from "./interface";

export const getTheRecentOeprations = createAsyncThunk<
  RecentOperationResponse,
  RecentOperationPayload
>("recentOperation/getRecentOperation", async (userID) => {
  const token = localStorage.getItem("token");
  let data: any;
  try {
    data = await apiHelper(
      `payment?accountId=${userID}`,
      "get",
      {},
      {
        Authorization: `Bearer ${token}`,
      }
    );
    const sortedData = data.data
    sortedData.data.sort((
        (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()))
    return sortedData;
  } catch (err: any) {
    data = {};
  }
  return data;
});
export const getTheRecentOeprationsPaginated = createAsyncThunk<
  RecentOperationPaginatedResponse,
  RecentOperationPaginatedPayload
>("recentOperation/getRecentOperation", async (payload) => {
  if(!payload?.userId) 
    return {
      data: {
        data: [],
      }
    };
  const token = localStorage.getItem("token");
  let data: any;
  try {
    data = await apiHelper(`payment/paginated?accountId=${payload?.userId}&page=${payload?.page}&search=${payload?.search}&limit=${payload?.limit}` ,"get",{},{
      Authorization: `Bearer ${token}`,
    });
    const sortedData = data.data
    sortedData?.data?.data.sort((
        (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()))
    return sortedData;
  } catch (err: any) {
    data = {};
  }
  // return data;
  return [];
});
