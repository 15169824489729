import { Crisp } from "crisp-sdk-web"
import { CRISP_ID } from "../constants/stringConstants"
import { getS3BaseUrl } from "./constants";
import axios from 'axios';
import { apiHelper } from "../api helper";
import { SuccessImage } from "../constants/Images";
import Swal from "sweetalert2";




export const loadCrisp = (firstName = 'Visitor', lastName = '', userId = '', userEmail = '', companyLogo = '', userPhoneNo = '') => {
    Crisp.configure(CRISP_ID);
    Crisp.load();
    Crisp.chat.show();
    const messageForm = document.getElementsByClassName("cc-1dthu")[0] as HTMLFormElement;
    if (messageForm) {
        const textarea = messageForm.querySelector("textarea") as HTMLTextAreaElement;
        if (!localStorage.getItem("crispTicketCreated")) {
            // Disable the textarea
            textarea.disabled = true;
            textarea.placeholder = "";
        }
    }

    if (firstName && lastName) Crisp.user.setNickname(firstName + " " + lastName);
    if (userId && userEmail) Crisp.session.setData({ user_id: userId, plan: "free", email: userEmail });
    if (userPhoneNo) Crisp.user.setPhone(userPhoneNo)
    if (userEmail) window.$crisp.push(["set", "user:email", [userEmail]]);
    if (companyLogo &&
        companyLogo !== "undefined" &&
        companyLogo !== '' &&
        companyLogo !== undefined &&
        !companyLogo?.includes('base64')) {
        const logo: any = companyLogo?.includes("CompanyLogos") ? getS3BaseUrl() + companyLogo : companyLogo;
        window.$crisp.push(["set", "user:avatar", [logo]]);
    }
}
export const loadPublicCrisp = () => {
    Crisp.configure(CRISP_ID);
    Crisp.load();
    Crisp.chat.show();
}

export const showIssueField = () => {
    if(!localStorage.getItem("crispOptionsDisplayed")) showCrispOptions()
    if (!localStorage.getItem("crispIssueFieldDisplayed") && localStorage.getItem("crispOptionsDisplayed")) {
        Crisp.message.show("field", {
            id: "issue",
            text: "How can we assist you with OneMoneyWay? Please describe your issue below",
            explain: "Briefly describe your issue here"
        });
        // Set the flag in localStorage
        localStorage.setItem("crispIssueFieldDisplayed", "true");
    }
    document.body.classList.add("hide-new-messages");
};
export const showCrispOptions = ()=>{
    if (!localStorage.getItem("crispIssueFieldDisplayed") && !localStorage.getItem("crispOptionsDisplayed")) {
        Crisp.message.show("picker", {
            "id": "options",
            "text": "Which module can we assist you with? Please choose from the options below:",
          
            "choices": [
              {
                "value": "sendFunds",
                "label": "Send Funds",
                "selected": false
              },
              {
                "value": "convert",
                "label": "Convert",
                "selected": false
              },
              {
                "value": "invoice",
                "label": "Invoice",
                "selected": false
              },
              {
                "value": "others",
                "label": "Others",
                "selected": false
              }
            ]
          });
          localStorage.setItem("crispOptionsDisplayed", "true");
        }  
        document.body.classList.add("hide-new-messages");
}

export const createTicketOnIssue = (userEmail:string) => {
    Crisp.chat.onChatOpened(()=>{
        const messageForm = document.getElementsByClassName("cc-1dthu")[0] as HTMLFormElement;
        if (messageForm) {
            const textarea = messageForm.querySelector("textarea") as HTMLTextAreaElement;
            if (!localStorage.getItem("crispTicketCreated")) {
                // Disable the textarea
                textarea.disabled = true;
                textarea.placeholder = "";
            }
        }
    })
    $crisp.push(["on", "message:received", async function (message: any) {
        if(message.type==="picker" && message.content.id ==='options' && message.content?.choices?.some((item:any)=>item.selected)){
            const selectedOption = message.content?.choices?.find((item:any)=>item.selected)
            localStorage.setItem('crispSelectedOption',selectedOption?.value)
            showIssueField();
        }
        if(message.from==="operator" && !localStorage.getItem('crispOperator')){
            localStorage.setItem("crispOperator",message?.user?.nickname)
        }else if (message.from==="operator" && localStorage.getItem('crispOperator') !=message.user.nickname && localStorage.getItem('hs_ticket_id')){
            const ticketId = localStorage.getItem('hs_ticket_id')
            localStorage.setItem("crispOperator",message.user.nickname)
            const token = localStorage.getItem("token");
            const { data } = await apiHelper("user/crisp-ticket-update-owner", "post", { ticketId, operator:message.user.nickname }, { Authorization: `Bearer ${token}` });
            localStorage.setItem("crispOperator",message.user.nickname)            
        }
        if (message.type === "field" && message.content.id === "issue" && message.content.value && !localStorage.getItem("crispTicketCreated") && localStorage.getItem('crispSelectedOption') ) {            
            try {
                const token = localStorage.getItem("token");
                const operator = localStorage.getItem('crispOperator')
                localStorage.setItem("crispTicketCreated", "true");
                const crispSessionId = localStorage.getItem(`crisp-client/session/${CRISP_ID}`);
                const { data } = await apiHelper("user/crisp-ticket-update", "put", { ticketContent: message.content.value,operator ,userEmail,crispSessionId,crispId:CRISP_ID}, { Authorization: `Bearer ${token}` });
                if (data.data?.properties && data.data.id) {
                    Crisp.message.sendText(message.content.value);
                    const messageForm = document.getElementsByClassName("cc-1dthu")[0] as HTMLFormElement;
                    if (messageForm) {
                        const textarea = messageForm.querySelector("textarea") as HTMLTextAreaElement;
                        if (localStorage.getItem("crispTicketCreated")) {
                            textarea.disabled = false;
                            textarea.placeholder = "Compose your message...";
                        }
                    }

                    localStorage.setItem("hs_ticket_id", data.data.id);

                    // Swal.fire({
                    //     title: data.data?.properties.subject+" created !",
                    //     text: "Your ticket id is "+ data.data.id,
                    //     imageUrl: SuccessImage,
                    //     imageAlt: 'Success Image'
                    //   })
                }
            } catch (error) {
                console.log(error)
            }
        }
    }]);
}

export const reloasSessionOnReseting= (firstName = 'Visitor', lastName = '', userId = '', userEmail = '', companyLogo = '', userPhoneNo = '')=>{
    $crisp.push([
        "on", "session:loaded", function () {
          loadCrisp(firstName,lastName,userId,userEmail,companyLogo,userPhoneNo)
        }
      ]);
}

export const crispSessionReset = () => {
    Crisp.configure(CRISP_ID);
    Crisp.session.reset();
    Crisp.chat.hide();
}