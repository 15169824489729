import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

const FallbackUi = () => {
  const { t } = useTranslation();
  return (
    <div className={styles["container-style"]}>
      <div className={styles["image-container-style"]}>
        <img src="crash.svg" className={styles["image-style"]} />
      </div>
      <span className={styles["title-style"]}>{t("errorScreen.title")}</span>
      <span className={styles["description-style"]}>
        {t("errorScreen.description")}{" "}
        <a
          href="mailto:support@onemoneyway.com"
          className={styles["email-style"]}
        >
          support@onemoneyway.com
        </a>
      </span>
    </div>
  );
};

export default FallbackUi;
