import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import "../index.css";
import { TransactionTable } from "./TransactionTable";
import TransactionDetailModal from "./TransactionDetailModal";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { B4BCardNotificationType } from "../../../redux/toolkit/cards/interface";
import { getAllCardsNotifications } from "../../../redux/toolkit/cards/operation";
import cardSelectors from "../../../redux/toolkit/cards/selector";
import { useTranslation } from "react-i18next";

const CardTransactionTable = (props: {
  viewAllTransactions: boolean,
  setViewAllTransactions: React.Dispatch<React.SetStateAction<boolean>>,
  selectedCardId: string,
}) => {
  const { viewAllTransactions, setViewAllTransactions, selectedCardId } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [transactionsList, setTransactionsList] = useState<B4BCardNotificationType[]>([]);
  const [filteredTransactionsList, setFilteredTransactionsList] = useState<B4BCardNotificationType[]>([]);
  const [showTransactionDetail, setShowTransactionDetail] = useState<boolean>(false);
  const [selectedTransaction, setSelectedTransaction] = useState<B4BCardNotificationType>();
  const { t } = useTranslation()

  const cardNotificationsSelector: any = useSelector(cardSelectors.cardNotificationsSelect);

  // fetch all card notifications for the logged in user
  useEffect(() => {
    dispatch(getAllCardsNotifications({ userId: localStorage.getItem("userId")! }))
  }, []);

  useEffect(() => {
    if (cardNotificationsSelector.status === "succeeded") {
      if (
        cardNotificationsSelector.data.data &&
        cardNotificationsSelector.data.data.length > 0
      ) {
        // Create a new array using spread syntax to avoid modifying the original array
        const sortedTransactions = [...cardNotificationsSelector.data.data].sort((a: B4BCardNotificationType, b: B4BCardNotificationType) =>
          moment.utc(b.transaction_timestamp,"YYYY-MM-DD hh:mm:ss").valueOf() - moment.utc(a.transaction_timestamp,"YYYY-MM-DD hh:mm:ss").valueOf()
        );
        setTransactionsList(sortedTransactions);
      } else {
        setTransactionsList([]);
      }
    }
  }, [cardNotificationsSelector.data]);

  // CREATE A NEW FILTERED TRANSACTION LIST BASED ON THE SELECTED CARD ID TO SHOW THE USER.
  useEffect(() => {
    if (transactionsList.length > 0) {
      const filteredTransactions = transactionsList.filter(transaction => transaction.token === Number(selectedCardId));
      setFilteredTransactionsList(filteredTransactions);
    } else {
      setFilteredTransactionsList([]);
    }
  }, [selectedCardId, transactionsList]);

  return (
    <>
      <div className="transactionDiv">
        <div className="col-12 alignRow" style={{ alignItems: "baseline" }}>
          <div style={{ display: "flex" }}>
            {viewAllTransactions &&
              <div className="goBackIcon" onClick={() => setViewAllTransactions(false)}>
                <AiOutlineArrowLeft />
              </div>
            }
            <h2 className="transactionTableheading">
              {/* {t("bankAccounts.recentOperations")} */}
              {t("bankAccountCards.transactions")}
            </h2>
          </div>
          {/* hide "View All" when in full view */}
          {!viewAllTransactions &&
            <div className="view-all" onClick={() => setViewAllTransactions(true)}>
              {t("bankAccountCards.viewAll")}
              <AiOutlineArrowRight style={{ fontSize: "0.9rem" }} />
            </div>
          }
        </div>

        <TransactionTable
          onRowClick={(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) => {
            setShowTransactionDetail(true)
            setSelectedTransaction(filteredTransactionsList[rowMeta.dataIndex])
          }}
          viewAllTransactions={viewAllTransactions}
          transactionsList={filteredTransactionsList}
        />
      </div>

      {showTransactionDetail && (
        <TransactionDetailModal
          showTransactionDetail={showTransactionDetail}
          setShowTransactionDetail={setShowTransactionDetail}
          selectedTransaction={selectedTransaction}
        />
      )}

    </>
  );
};

export default CardTransactionTable;
